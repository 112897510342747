import API from "Util/Axios"
import { useForm } from "antd/lib/form/Form"
import { useEffect, useState } from "react"

const { Modal, Form, Input, message } = require("antd")

const RefrigeranteGuardar=({doc,setDoc,onGuardado})=>{
    const [form] = useForm()
    const [cargando,setCargando] = useState(false)

    const onOk=()=>{
        form.submit()
    }

    useEffect(()=>{
        if(doc){
            form.setFieldsValue(doc)
        }else{
            form?.resetFields()
        }
    },[doc])

    const guardar=async (payload)=>{
        try{
            setCargando(true)

            const url = doc._id ? `refrigerante/${doc._id}` : 'refrigerante'
            const method = doc._id ? 'put' : 'post'

            const {data} = await API({
                url,
                method,
                data:payload
            })
            setDoc(null)
            onGuardado(data.data)
            message.success('Refrigerante guardado')
        }catch(error){
            message.error(error.toString())
        }finally{
            setCargando(false)
        }
    }

    return <Modal title="Guardar refrigerante" onOk={onOk} okText={'Guardar'} cancelButtonProps={{style:{display:'none'}}} maskClosable={false} keyboard={false} onCancel={()=>setDoc(null)} closable={!cargando} visible={doc}>
        <Form form={form} onFinish={guardar} defaultValue={{nombre:''}}>
            <Form.Item name="nombre" label="Nombre" rules={[{required:true,message:'Ingrese un nombre'}]}>
                <Input/>
            </Form.Item>
            <Form.Item name="pcb" label="PCA (KG CO2/KG)" rules={[{required:true,message:'Campo requerido'}]}>
                <Input/>
            </Form.Item>
            <Form.Item name="ele" label="Electricidad consumida (TOn CO2/KWH)" rules={[{required:true,message:'Campo requerido'}]}>
                <Input/>
            </Form.Item>
            <Form.Item name="gas" label="Gasolina consumida (TOn CO2/galones)" rules={[{required:true,message:'Campo requerido'}]}>
                <Input/>
            </Form.Item>
        </Form>
    </Modal>
}

export default RefrigeranteGuardar